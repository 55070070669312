/* Import your custom font */
@font-face {
  font-family: 'Demonized';
  src: local("Demonized"), url('./Demonized.ttf') format('truetype'),
  url("./Demonized.woff2") format("woff2");
}

/* Apply the custom font to the body */
body {
  margin: 0;
  font-family: 'Demonized', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


