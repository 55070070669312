/* NavIcon.css */
.navbar-icon {
  position: relative;
  height: 40px; /* Height for the navbar */
}

.navbar-icon img {
  max-height: 50px; /* Limit the height of the icon */
}

.menu {
  position: fixed;
  top: 0;
  left: -250px; /* Initially hide the menu off the screen */
  width: 250px; /* Adjust as needed */
  height: 100%; /* Cover the entire height of the screen */
  background-color: #000; /* Background color for the menu */
  color: #fff; /* Text color for the menu */
  padding-top: 40px; /* Adjust based on the height of the navbar */
  transition: left 0.3s ease; /* Add transition for smooth animation */
  z-index: 1000; /* Ensure it's above other content */
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu ul li {
  padding: 15px 20px; /* Increase padding for menu items, 20px added on the left side */
  margin: 0; /* Remove margin */
  border-bottom: 1px solid #555; /* Add a line between menu items */
}

.menu ul li:last-child {
  border-bottom: none; /* Remove the line from the last menu item */
}

.menu ul li a {
  text-decoration: none;
  color: #fff; /* Adjust as needed */
  display: block; /* Make links fill entire width of menu */
  transition: background-color 0.3s ease; /* Add transition for hover effect */
}

.menu ul li a:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Hover background color */
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 999; /* Ensure backdrop covers everything */
  display: none;
}

.menu.open {
  left: 0; /* Slide the menu into view */
}

.backdrop.show {
  display: block; /* Show the backdrop when menu is open */
}
