.carousel-container {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 300px; /* Set a fixed height for the carousel */
  margin: auto;
}

.slide {
  width: auto;
  height: 100%;
  max-width: 100%;
  object-fit: cover; /* Ensure images fill the container while maintaining aspect ratio */
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  outline: none;
  z-index: 1;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
