
.backg{
  background-color: #e2e2e2fa;
}
.homepage {
  background-color: white;
  margin: auto;
  padding-top: 10vh;
  width: 1024px;
  font-family: 'Demonized'
}
.headimg{
  width: 100%;

  height: auto;
  margin-bottom: 5px;
}

.hero {
  /* Styles for the hero section (large image) */
  background-image: url("./rink.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 500px; /* Set the height as per your requirement */
  position: relative; /* To position the hero content */
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.hero-content h1 {
  font-family: 'Demonized', sans-serif;
  color: #000000; /* Text color */
}

.hero-content p {
  color: #000000; /* Text color */
}

.button {
  display: inline-block;
  background-color: #5c0e89;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none; /* Remove underline */
  border-radius: 5px;
  margin-top: 20px;
}


.content {
  /* Styles for the content sections */
}
.homepage p{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.homepage ul{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}
.homepage li{
  padding: 3px;
  font-size: 16px;
}
.instagram-feed {
  display: flex; /* Display items vertically */
  justify-content: center; /* Center items horizontally */
}


.container {
padding: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.column {
 box-sizing: border-box;
 width: 50%;


}

.text-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
margin-right: 25px;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.text-column h2,
.text-column p {
  text-align: left;
  font-size: 18px;

}
.text-column h2{
  text-align: center;
}



/* Responsive styles */
@media screen and (max-width: 768px) {
  .instagram-feed {
    flex-direction: column; /* Display items vertically */
    align-items: center; /* Center items horizontally */
  }
  .backg{
    background-color: white;
  }
  .row {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }
  .column {
    box-sizing: border-box;
    width: 100%;
   }
}
  
 
  
  
 
  