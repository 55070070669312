/* AboutPage.css */
.back{
background-color: #e3e3e3a1;
margin: auto;
}
.center{
  background-color: white;
  margin: auto;
  max-width: 1024px;
}
.about-container {
  
background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto;
    height: 100%;
    padding-bottom: 25px;
   
  }
  .about-container h3{
    margin-bottom: 20px;
  }
  
  .about-content {
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: row;
  }
  .firstsection{
    width: 50%;
  }
  .secondsection{
    width: 50%;
  }
  
  .about-content img {
    max-width: 100%;
  }
  
  .about-content p {
    padding-top: 15px;
    text-align: left;
    font-size: 18px;
    line-height: 1.6;
  }
  .about-content img {
    max-width: 250px; /* Set the maximum width to 350px */
    height: auto; /* Ensure the aspect ratio is maintained */
 
  }
  .im{
    width: 1024px;
    padding-top: 10vh;
  }
 .about-container p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
 
  @media screen and (max-width: 768px) {
    .firstsection{
      width: 100%;
    }
    .secondsection{
      width: 100%;
    }
    .about-content {
      flex-direction: column; /* On laptops and larger screens, display in row */
    }
    .im{
      width: 100%;;
    }
    .back{
      background-color: #a1a1a1a1;
      margin: auto;
      }
      .center{
        background-color: white;
        margin: auto;
      max-width: 100%;
      }
  }
  