.homepage{
    max-width: 80%;
    margin: auto;
    padding-top: 115px;
  }
  /* Styles for Header with Video */
  .headh {
    /* display: inline-block; */
     
    }
    .inline{
      /* display: flex;
      padding: 15px; */
    }
    .choose{
      /* margin-left:20px ;
      padding: 5px;
      display: inline-block; */
    }
    .ttt{
      margin-top: 50px;
    }
  
    .ct{
      text-align: center;
    }
    .testimg{
      width: 80% ;
      height: auto;
    
    }
    
    .header-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    /* Testimonial styles */
    .testimonials {
      display: flex;
      justify-content: space-between;
      padding: 20px 0; /* Adjust padding as needed */
     
    }
    .cent{
      display: flex;
      justify-content: center; /* Align horizontally */
      align-items: center; /* Align vertically */
      position: relative;
    }
    .react-player {
      position: relative;
      left: 0;
      top: 0;
      transform: none;
  }
    .testimonial {
      flex: 1;
      margin: 0 10px; /* Adjust margin as needed */
      background-color: #f0f0f0; /* Example background color */
      padding: 20px;
    }
    
    /* Call to Action styles */
    .cta  {
      text-align: center;
      padding: 20px 0; /* Adjust padding as needed */
    }
    .cta p {
      text-align: center;
      /* Adjust padding as needed */
    }
    
    .cta h2 {
      margin-bottom: 10px;
    }
    .soj{
      width: 700px;
      height: 100%;
      object-fit: contain;
    }
    .choose p{
      font-size: 18px;
      text-align: center;
      justify-content: center;
    }
    .cta p {
      margin-bottom: 20px;
    }
    
    .butp{
      background-color: #000000;
      color: #fff;
      border: none;
      padding: 10px 20px;
      margin: 25px;
      font-size: 16px;
      cursor: pointer;
      text-decoration: solid;
    }
    .buf{
      padding:  20px;
      padding-bottom: 50px;
    }
      h2{
        text-align: center;
        justify-content: center ;
      }
      .toppad{
        padding: 25px;
      }
      p{
        text-align: center;
        justify-content: center;
      }
      .container1{ 
        padding: 20px;
        padding-top:150px ;
       
      }
    /* Responsive styles */
    @media screen and (max-width: 768px) {
      .header {
        height: 300px; /* Adjust height for mobile */
      }
      .soj{
    
        width: 100%;
        height: 100%;
        object-fit: cover;  /* Or any other object-fit value */
     
      }
      .testimonials {
        flex-direction: column; /* Stack testimonials vertically on mobile */
      }
    
      .testimonial {
        margin: 10px 0; /* Adjust margin for mobile */
      }
      .h1{
        font-size: 16px;
        
      }
   
    }
    