/* ProductPage.css */
.top{
  margin: auto;
  padding-top: 10vh;
  max-width: 1024px;
}
.product-page {
    align-items: center;
     /* Light grey background */
    padding: 20px;
   background-color: white;
    max-width: 1024px;
    margin: auto;
   
  }
  h3{
     /* text-align: center; */
  }
  .product { 
    margin: auto;
    background-color: #fff; /* White background for each product */
    margin-bottom: 20px;
    padding: 20px;
    max-width: 80%;
    background-color: #f0f0f0;
   
    
  }
  .checkout-btn {
    width: 80%;
    display: block;  
    margin:  auto;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
    padding: 10px;
    background-color: #001848;
    color: #fff;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  
  }
  .product-image {
    max-height: 100px;
    width: auto;
    margin-right : 15px;
    margin-top: 15px;
  }
  
  .reviews {
    display: flex;
    
  }
  .reviews p {
    padding-right: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .map-location {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    max-width: 800px;
    margin: auto;
    transition: background-color 0.3s;
    margin-bottom: 25px;
  }
  
  .map-location:hover {
    background-color: #f0f0f0;
  }
  
  .map-image {
    flex: 1;
    margin-right: 10px;
  }
  
  .map-image img {
    max-width: 250px;
    height: auto;
  
  }
  
  .location-details {
flex:auto;
  }
  
  .location-details h3 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  
  .location-details p {
    text-align: start;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .product-page p{
    text-align: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  }
  @media screen and (max-width: 768px) {
    .top{
      margin: auto;
      padding-top: 12vh;
      max-width: 100%;
    }
    .divgo{
      margin :auto;
      margin-bottom: 10px;
      
    }
    .map-image img {
      max-width: 150px;
      height: auto;
    
    }
    .inlin{
      display: flex; 
  flex-direction: column; 
    }
  }
.inlin{
  display: flex;
}