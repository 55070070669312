/* Footer.css */

.footer {
    background-color: #000000; /* Same blue color as header */
    color: #fff;
    text-align: center;
    padding: 20px 0; /* Adjust padding as needed */
  }
  
  .footer p{
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .footer {
      font-size: 14px; /* Adjust font size for mobile */
    }
  }
  