.contact-form-container {
max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.contact-form-container label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  text-align: start;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.contact-form-container input[type='text'],
.contact-form-container input[type='email'],
.contact-form-container textarea {
  width: calc(100% - 22px); /* Subtract 22px for padding */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form-container textarea {
  height: 100px;
}

.contact-form-container input[type='submit'] {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.contact-form-container input[type='submit']:hover {
  background-color: #0056b3;
}
